import Vue from 'vue';
import App from './layouts/Main.vue';
import vuetify from './plugins/vuetify' // path to vuetify export
import axios from 'axios'
import router from "./routes";
import store from "./store";
import {AUTH_LOGOUT} from "./store/actions/auth";

Vue.config.productionTip = false;

const token = localStorage.getItem('user-token')
if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer '+token
}

axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded";


function createAxiosResponseInterceptor() {
    const interceptor = axios.interceptors.response.use(
        response => response,
        function(error) {
            // Reject promise if usual error
            if (error.response.status !== 401) {
                return Promise.reject(error);
            }

            /*
             * When response code is 401, try to refresh the token.
             * Eject the interceptor so it doesn't loop in case
             * token refresh causes the 401 response
             */
            axios.interceptors.response.eject(interceptor);

            return axios.post('/api/auth/refresh', {

            }).then(response => {
                localStorage.setItem("user-token", response.data.token);
                // Here set the header of your ajax library to the token value.
                // example with axios
                axios.defaults.headers.common['Authorization'] = 'Bearer '+response.data.token;
                error.response.config.headers['Authorization'] = 'Bearer ' + response.data.token;
                return axios(error.response.config);
            }).catch(error => {
                store.dispatch(AUTH_LOGOUT)
                    .then(() => {
                        window.location.href = "/login";
                    });
                return Promise.reject(error);
            }).finally(createAxiosResponseInterceptor);
        }
    );
}
createAxiosResponseInterceptor();
// axios.interceptors.response.use(function (response) {
//     // let headers = response;
//     // console.log(headers);
//     // // your 401 check here
//     // // token refresh - update client session
//     // if (headers.authorization !== undefined) {
//     //     axios.defaults.headers.common['Authorization'] = 'Bearer '+headers.authorization
//     //     localStorage.setItem("user-token", headers.authorization);
//     // }
//
//     return response
// }, function (error) {
//     // Do something with response error
//     // check for errorHandle config
//
//     // if has response show the error
//     if (error.response) {
//
//         if (error.response.status === 401 && error.config && !error.response.config.__isRetryRequest) {
//             // // if you ever get an unauthorized, logout the user
//             store.dispatch(AUTH_LOGOUT)
//                 .then(() => {
//                     window.location.href = "/";
//                 });
//             // // you can also redirect to /login if needed !
//         }
//     }
//     return Promise.reject(error)
// });

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (process.env.NODE_ENV !== 'production') {
        config.withCredentials = true;
        config.baseURL = 'http://vyatkina.test'
    }
    // config.validateStatus = function (status) {
    //     return status < 402;
    // };
    return config;
}, function () {
    // Do something with request error
});

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app');
