// routes.js
import Vue from "vue";
import Router from "vue-router";
const Home = () => import( './components/Home.vue');
const Products = () => import( './components/Products.vue');
const Recommends = () => import( './components/Recommends.vue');
const WbStat = () => import( './components/WbStat.vue');
const Orders = () => import( './components/Orders.vue');
const Reports = () => import( './components/ReportDesigner.vue');
const Login = () => import( './components/Login.vue');
const NotFoundComponent = () => import( './components/NotFound.vue');

import store from "./store";

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/");
};

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/login");
};

export default new Router({
    mode: "history",
    routes: [
    { path: '/', component: Home , name: 'home', beforeEnter: ifAuthenticated},
    { path: '/orders', component: Orders , name: 'orders', beforeEnter: ifAuthenticated},
    { path: '/products', component: Products , name: 'products', beforeEnter: ifAuthenticated},
    { path: '/reports', component: Reports , name: 'reports', beforeEnter: ifAuthenticated},
    { path: '/recommends', component: Recommends , name: 'recommends', beforeEnter: ifAuthenticated},
    { path: '/wbstat', component: WbStat , name: 'wbstat', beforeEnter: ifAuthenticated},
    { path: '/login', component: Login , name: 'login', beforeEnter: ifNotAuthenticated},
    { path: '*', component: NotFoundComponent  }
]});

