import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from "../actions/auth";
import { USER_REQUEST } from "../actions/user";
import axios from 'axios'


const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
    permissions: localStorage.getItem("user-permissions") || null,
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
        axios({ url: "/api/auth/login", data: user, method: "POST" })
        .then(resp => {
          localStorage.setItem("user-token", resp.data.token);
            localStorage.setItem("user-permissions", JSON.stringify(resp.data.permissions));
          // Here set the header of your ajax library to the token value.
          // example with axios
          axios.defaults.headers.common['Authorization'] = 'Bearer '+resp.data.token
          commit(AUTH_SUCCESS, resp.data);
          dispatch(USER_REQUEST);
          resolve(resp.data);
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          localStorage.removeItem("user-token");
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {
        axios({url: '/api/auth/logout',  method: 'POST' }).then(resp => {
            localStorage.removeItem("user-token");
            localStorage.removeItem("user-permissions");
            delete axios.defaults.headers.common['Authorization']
            commit(AUTH_LOGOUT);
            resolve(resp.data);
        })
            .catch(err => {
                commit(AUTH_ERROR, err);
                localStorage.removeItem("user-token");
                localStorage.removeItem("user-permissions");
                delete axios.defaults.headers.common['Authorization']
                resolve(err.data);
            });

    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
      state.permissions = resp.permissions;
    state.token = resp.token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
