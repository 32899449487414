// App.vue

<template>
    <div id="app">

        <v-app>
            <v-navigation-drawer
                    v-model="drawer"
                    app
            >
                <v-list dense nav>
                    <v-list-item link exact :to="{ name: 'login' }" v-if="!ifAuthenticated()">
                        <v-list-item-action>
                            <v-icon>mdi-login</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Авторизация</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link exact :to="{ name: 'home' }" v-if="ifAuthenticated()">
                        <v-list-item-action>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Главная</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link exact :to="{ name: 'products' }" v-if="typeof (permissions) == 'object' && (permissions.products || permissions.all)">
                        <v-list-item-action>
                            <v-icon>mdi-chart-line</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Товары</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link exact :to="{ name: 'orders' }" v-if="typeof (permissions) == 'object' && (permissions.orders || permissions.all)">
                        <v-list-item-action>
                            <v-icon>mdi-chart-line</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Заказы</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link exact :to="{ name: 'recommends' }" v-if="typeof (permissions) == 'object' && (permissions.recommends || permissions.all)">
                        <v-list-item-action>
                            <v-icon>mdi-shuffle</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Рекомендации</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link exact :to="{ name: 'wbstat' }" v-if="typeof (permissions) == 'object' && (permissions.wbstat || permissions.all)">
                        <v-list-item-action>
                            <v-icon>mdi-chart-bar</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Стата WB</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link exact :to="{ name: 'reports' }" v-if="typeof (permissions) == 'object' && (permissions.reports || permissions.all)">
                        <v-list-item-action>
                            <v-icon>mdi-chart-line</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Отчеты</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click="logout" v-if="ifAuthenticated()">
                        <v-list-item-action>
                            <v-icon>mdi-export</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Выйти</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
            <v-app-bar
                    app
                    class="primary"
            >
                <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="white" />
                <v-spacer></v-spacer>
                сайт
                <v-spacer></v-spacer>
            </v-app-bar>
            <v-main>
                <router-view  />
            </v-main>
            <v-footer
                    app
            >
                <span>&copy; {{new Date().getFullYear()}}</span>
            </v-footer>
        </v-app>
    </div>
</template>

<script>
    import { AUTH_LOGOUT } from "../store/actions/auth";
    import axios from "axios";

    import store from "../store";


    export default {
        data: () => ({
            drawer: null,
            baseDir: '',
            sizeTab: false,
            discountTab: false,
            permissions:JSON.parse(localStorage.getItem("user-permissions"))||[]
        }),
        mounted() {
            window.addEventListener("storage", this.onStorageUpdate);
            if (store.getters.isAuthenticated) {
                axios
                    .get('/api/wb/getarc')
                    .then(response => {
                        console.log(response.data);
                    }).catch(function () {
                    console.log('Ошибка создания архива');
                });
            }
        },
        beforeDestroy() {
            window.removeEventListener("storage", this.onStorageUpdate);
        },
        methods: {
            ifAuthenticated () {
                return store.getters.isAuthenticated;
            },
            onStorageUpdate(event) {
                if (event.key === "user-token") {
                    if (event.newValue == undefined || event.newValue == null || event.newValue.length === 0) {
                        this.logout();
                    }
                    else {
                        axios.defaults.headers.common['Authorization'] = 'Bearer '+event.newValue;
                    }
                }
            },
            logout: function () {
                this.$store.dispatch(AUTH_LOGOUT)
                    .then(() => {
                        this.$router.push('/login')
                    })
            }
        },

        created()  {
            if (process.env.NODE_ENV !== 'production') {
                this.baseDir = 'http://vyatkina.test'
            }

        },


    }
</script>
